<template>
  <div class="imgBox">
    <div class="imgBox1" id="content1">
      <div class="img1">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <!-- id是为了实现滚动 -->
    <div class="imgBox2" id="content2">
      <div class="img2">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3" id="content3">
      <div class="img3">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4" id="content4">
      <div class="img4">
        <img :src="importedImg4" alt="" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5">
      <div class="img5">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>
    <div class="imgBox6" id="content5">
      <div class="img6">
        <img :src="importedImg6" :alt="altText" class="imgItem6" />
      </div>
    </div>
  </div>
</template>

<script>
import importedImg1 from "@/page/chatchaimobile/components/img/1_1.png";
import importedImg2 from "@/page/chatchaimobile/components/img/1_2.png";
import importedImg3 from "@/page/chatchaimobile/components/img/1_3.png";
import importedImg4 from "@/page/chatchaimobile/components/img/1_4.png";
import importedImg5 from "@/page/chatchaimobile/components/img/1_5.png";
import importedImg6 from "@/page/chatchaimobile/components/img/6662.png";
import './index.css'
export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  background-color: white;
}
.imgBox1,
.imgBox3,
.imgBox4 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
}
img {
  display: block;
}
.img1 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}
.img3,
.img4 {
  width: 100%;
  overflow: hidden;
  max-width: 1620px;
  margin-left: 0;
  margin-right: 0;
}

.img2,
.img5,
.img6 {
  max-width: 1620px;
  margin-left: 45px;
  margin-right: 45px;
}

.img5 {
  margin-top: 100px;
  margin-bottom: 100px;
}
.img6 {
  margin-bottom: 200px;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
  margin-top: 90px;
  margin-bottom: 0px;
}
.imgItem3 {
  max-width: 100%;

  margin-bottom: 0;
}
.imgItem4 {
  width: 100%;
  margin-bottom: 0;
}
.imgItem5 {
  max-width: 100%;
  margin-bottom: 0;
}
.imgItem6 {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0;
}
</style>
