<template>
  <div id="app">
    <MyHeaderVue/>
    <router-view></router-view>
    <MyFooterVue/>
  </div>
</template>

<script>
import MyHeaderVue from './page/chatchaimobile/MyHeader.vue'
import MyFooterVue from './page/chatchaimobile/MyFooter.vue'

// import MyHeaderVue from './page/jaidee/MyHeader.vue'
// import MyFooterVue from './page/jaidee/MyFooter.vue'
export default {
  name: 'App',
  components: {
    MyHeaderVue,
    MyFooterVue
  }
}
</script>

<style>
#app {
  color: #2c3e50;
}
</style>
