// router.js
import Vue from 'vue'
import VueRouter from 'vue-router'
// 越南namvaytien
// import MyFirst from './namvaytien/MyFirst.vue'
// import MyAbout from './namvaytien/MyAbout.vue'
// import MyProtocol from './namvaytien/MyProtocol.vue'
// import MyQuestion from './namvaytien/MyQuestions.vue'

//泰国chatchaimobile
import MyFirst from './chatchaimobile/MyFirst.vue'
import MyProtocol from './chatchaimobile/MyProtocol.vue'


//泰国jaidee
// import MyFirst from './jaidee/MyFirst.vue'
// import MyProtocol from './jaidee/MyProtocol.vue'
// import MyService from './jaidee/MyService.vue'
// import MyQuestion from './jaidee/MyQuestions.vue'
Vue.use(VueRouter)
//越南namvaytien
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]

//泰国chatchaimobile
  const routes = [
    {
      path: '/',
      component: MyFirst, // 默认路由
    },
   
    {
      path: '/protocol',
      component: MyProtocol, // protocol 路由
    },
  ]

//泰国jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/service',
//         component: MyService, // /list 路由
//       },
   
//   ]



const router = new VueRouter({
  mode: 'hash', // 设置路由模式为 hash
  routes
})

export default router
